import Link from "next/link";
import { ForwardedRef, forwardRef } from "react";

import Icon, { IIcon } from "apps/website/components/base/Icon/Icon";
import {
  BreakpointSize,
  Color,
  Display,
  Size,
} from "apps/website/components/base/Text/Text.map";
import Text from "apps/website/components/base/Text/Text";
import { Justify, defaultJustifyClassMap } from "apps/website/maps/Flex.map";

import { ButtonComponent } from "../Button/Button.map";

import { Height, iconButtonHeight } from "./IconButton.map";

export interface IIconButton extends IIcon {
  label: string;
  disabled?: boolean;
  color?: Color;
  tabIndex?: number;
  id?: string;
  children?: string;
  className?: string;
  buttonClassName?: string;
  iconClassName?: string;
  justify?: Justify;
  display?: Display;
  textSize?: Size | BreakpointSize;
  to?: string;
  height?: Height;
  textClassName?: string;
  onClick?:() => void;
}

const IconButton = forwardRef<HTMLButtonElement | HTMLAnchorElement, IIconButton>(({
  label, color = "default", icon, size = "default", to, onClick, disabled = false, tabIndex = 0, id = undefined, className, textClassName, display = "default", textSize = "default", justify = "center", iconClassName, height = "default", buttonClassName, children,
}, ref) => {

  const componentTag = to ? ButtonComponent.LINK : ButtonComponent.DEFAULT;
  const classList = `min-w-11 flex items-center hover:opacity-50 transition-opacity duration-300 opacity-100 ${className} ${defaultJustifyClassMap[justify]} ${iconButtonHeight[height]}`;

  return (
    <>
      { componentTag === ButtonComponent.DEFAULT || !to ? (
        <button
          type="button"
          data-component={IconButton.displayName}
          id={id}
          tabIndex={tabIndex}
          aria-label={label}
          onClick={onClick}
          disabled={disabled}
          className={`min-h-11 ${buttonClassName}`}
          ref={ref as ForwardedRef<HTMLButtonElement>}
        >
          <div className={classList}>
            { children && (
              <Text tag="span" color={color} display={display} size={textSize} className={`mr-2 ${textClassName}`}>
                { children }
              </Text>
            ) }
            <Icon icon={icon} color={color} size={size} className={iconClassName}/>
          </div>
        </button>
      ) : (
        <Link
          href={to}
          data-component={IconButton.name}
          id={id}
          tabIndex={tabIndex}
          aria-label={label}
          onClick={onClick}
          className={classList}
          ref={ref as ForwardedRef<HTMLAnchorElement>}
        >
          { children && (
            <Text tag="span" color={color} display={display} className={`mr-2 ${textClassName}`}>
              { children }
            </Text>
          ) }
          <Icon icon={icon} color={color} size={size} />
        </Link>
      ) }
    </>
  );
});

IconButton.displayName = "IconButton";

export default IconButton;
