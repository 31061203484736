import { FC, ReactNode, memo } from "react";

import { makeBreakpointClasses } from "apps/website/utils/classes";
import * as FlexMap from "apps/website/maps/Flex.map";
import { breakpointFlexClasses } from "apps/website/utils/classes/flex";
import { WithTestID } from "apps/website/types";

import * as ColumnMap from "./Column.map";

export interface IColumn extends WithTestID {
  tag?: ColumnMap.AllowedTag;
  spans?: ColumnMap.Span | ColumnMap.BreakpointSpan;
  offset?: ColumnMap.Offset | ColumnMap.BreakpointOffset;
  justify?: FlexMap.Justify | FlexMap.BreakpointJustify;
  align?: FlexMap.Align | FlexMap.BreakpointAlign;
  direction?: FlexMap.Direction | FlexMap.BreakpointDirection;
  id?: string;
  label?: string;
  component?: string;
  children: ReactNode;
  className?: string;
  onClick?: () => void;
  "data-linkingid"?: string;
}

const Column: FC<IColumn> = ({
  "data-testid": testId,
  "data-linkingid": linkingId,
  tag = "div",
  spans = 12,
  justify = "default",
  align = "default",
  offset = "default",
  direction = "column",
  id = undefined,
  label = undefined,
  component = "Column",
  children,
  className = "",
  onClick,
}) => {
  const Tag = tag;

  const computedClasses = makeBreakpointClasses([
    [ spans, ColumnMap.defaultSpansClassMap, ColumnMap.collectiveSpansClassMap ],
    [ offset, ColumnMap.defaultOffsetClassMap, ColumnMap.collectiveOffsetClassMap ],
    ...breakpointFlexClasses(align, justify, direction),
  ], className);

  return (
    <Tag data-component={component} data-testid={testId} data-linkingid={linkingId} id={id} aria-label={label} className={`flex box-border ${computedClasses}`} onClick={onClick}>
      { children }
    </Tag>
  );
};

export default memo(Column);
