import { Breakpoint, BreakpointMap } from "apps/website/maps/Breakpoint.map";

export const allowedTags = [ "div", "li", "article", "nav" ] as const;

export type Span = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export type Offset = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13;
export type BreakpointSpan = {
  [key in Breakpoint]?: Span;
};

export type BreakpointOffset = {
  [key in Breakpoint]?: Offset;
};

export type SpanMap = {
  [key in Span]: string;
};

export type OffsetMap = {
  [key in Offset]: string;
};

export interface ISpanMapWithDefault extends SpanMap {
  default: string;
}
export interface IOffsetMapWithDefault extends OffsetMap {
  default: string;
}

export type AllowedTag = typeof allowedTags[number];

export const defaultSpansClassMap: ISpanMapWithDefault = {
  1: "col-span-1",
  2: "col-span-2",
  3: "col-span-3",
  4: "col-span-4",
  5: "col-span-5",
  6: "col-span-6",
  7: "col-span-7",
  8: "col-span-8",
  9: "col-span-9",
  10: "col-span-10",
  11: "col-span-11",
  12: "col-span-12",
  default: "col-span-12",
};

export const smallSpansClassMap: SpanMap = {
  1: "sm:col-span-1",
  2: "sm:col-span-2",
  3: "sm:col-span-3",
  4: "sm:col-span-4",
  5: "sm:col-span-5",
  6: "sm:col-span-6",
  7: "sm:col-span-7",
  8: "sm:col-span-8",
  9: "sm:col-span-9",
  10: "sm:col-span-10",
  11: "sm:col-span-11",
  12: "sm:col-span-12",
};

export const mediumSpansClassMap: SpanMap = {
  1: "md:col-span-1",
  2: "md:col-span-2",
  3: "md:col-span-3",
  4: "md:col-span-4",
  5: "md:col-span-5",
  6: "md:col-span-6",
  7: "md:col-span-7",
  8: "md:col-span-8",
  9: "md:col-span-9",
  10: "md:col-span-10",
  11: "md:col-span-11",
  12: "md:col-span-12",
};

export const largeSpansClassMap: SpanMap = {
  1: "lg:col-span-1",
  2: "lg:col-span-2",
  3: "lg:col-span-3",
  4: "lg:col-span-4",
  5: "lg:col-span-5",
  6: "lg:col-span-6",
  7: "lg:col-span-7",
  8: "lg:col-span-8",
  9: "lg:col-span-9",
  10: "lg:col-span-10",
  11: "lg:col-span-11",
  12: "lg:col-span-12",
};

export const extraLargeSpansClassMap: SpanMap = {
  1: "xl:col-span-1",
  2: "xl:col-span-2",
  3: "xl:col-span-3",
  4: "xl:col-span-4",
  5: "xl:col-span-5",
  6: "xl:col-span-6",
  7: "xl:col-span-7",
  8: "xl:col-span-8",
  9: "xl:col-span-9",
  10: "xl:col-span-10",
  11: "xl:col-span-11",
  12: "xl:col-span-12",
};

export const twoExtraLargeSpansClassMap: SpanMap = {
  1: "2xl:col-span-1",
  2: "2xl:col-span-2",
  3: "2xl:col-span-3",
  4: "2xl:col-span-4",
  5: "2xl:col-span-5",
  6: "2xl:col-span-6",
  7: "2xl:col-span-7",
  8: "2xl:col-span-8",
  9: "2xl:col-span-9",
  10: "2xl:col-span-10",
  11: "2xl:col-span-11",
  12: "2xl:col-span-12",
};

export const threeExtraLargeSpansClassMap: SpanMap = {
  1: "3xl:col-span-1",
  2: "3xl:col-span-2",
  3: "3xl:col-span-3",
  4: "3xl:col-span-4",
  5: "3xl:col-span-5",
  6: "3xl:col-span-6",
  7: "3xl:col-span-7",
  8: "3xl:col-span-8",
  9: "3xl:col-span-9",
  10: "3xl:col-span-10",
  11: "3xl:col-span-11",
  12: "3xl:col-span-12",
};

export const collectiveSpansClassMap: BreakpointMap = {
  default: defaultSpansClassMap,
  sm: smallSpansClassMap,
  md: mediumSpansClassMap,
  lg: largeSpansClassMap,
  xl: extraLargeSpansClassMap,
  "2xl": twoExtraLargeSpansClassMap,
  "3xl": threeExtraLargeSpansClassMap,
};

export const defaultOffsetClassMap: IOffsetMapWithDefault = {
  default: "col-start-auto",
  1: "col-start-1",
  2: "col-start-2",
  3: "col-start-3",
  4: "col-start-4",
  5: "col-start-5",
  6: "col-start-6",
  7: "col-start-7",
  8: "col-start-8",
  9: "col-start-9",
  10: "col-start-10",
  11: "col-start-11",
  12: "col-start-12",
  13: "col-start-13",
};

export const smallOffsetClassMap: OffsetMap = {
  1: "sm:col-start-1",
  2: "sm:col-start-2",
  3: "sm:col-start-3",
  4: "sm:col-start-4",
  5: "sm:col-start-5",
  6: "sm:col-start-6",
  7: "sm:col-start-7",
  8: "sm:col-start-8",
  9: "sm:col-start-9",
  10: "sm:col-start-10",
  11: "sm:col-start-11",
  12: "sm:col-start-12",
  13: "sm:col-start-13",
};

export const mediumOffsetClassMap: OffsetMap = {
  1: "md:col-start-1",
  2: "md:col-start-2",
  3: "md:col-start-3",
  4: "md:col-start-4",
  5: "md:col-start-5",
  6: "md:col-start-6",
  7: "md:col-start-7",
  8: "md:col-start-8",
  9: "md:col-start-9",
  10: "md:col-start-10",
  11: "md:col-start-11",
  12: "md:col-start-12",
  13: "md:col-start-13",
};

export const largeOffsetClassMap: OffsetMap = {
  1: "lg:col-start-1",
  2: "lg:col-start-2",
  3: "lg:col-start-3",
  4: "lg:col-start-4",
  5: "lg:col-start-5",
  6: "lg:col-start-6",
  7: "lg:col-start-7",
  8: "lg:col-start-8",
  9: "lg:col-start-9",
  10: "lg:col-start-10",
  11: "lg:col-start-11",
  12: "lg:col-start-12",
  13: "lg:col-start-13",
};

export const extraLargeOffsetClassMap: OffsetMap = {
  1: "xl:col-start-1",
  2: "xl:col-start-2",
  3: "xl:col-start-3",
  4: "xl:col-start-4",
  5: "xl:col-start-5",
  6: "xl:col-start-6",
  7: "xl:col-start-7",
  8: "xl:col-start-8",
  9: "xl:col-start-9",
  10: "xl:col-start-10",
  11: "xl:col-start-11",
  12: "xl:col-start-12",
  13: "xl:col-start-13",
};

export const twoExtraLargeOffsetClassMap: OffsetMap = {
  1: "2xl:col-start-1",
  2: "2xl:col-start-2",
  3: "2xl:col-start-3",
  4: "2xl:col-start-4",
  5: "2xl:col-start-5",
  6: "2xl:col-start-6",
  7: "2xl:col-start-7",
  8: "2xl:col-start-8",
  9: "2xl:col-start-9",
  10: "2xl:col-start-10",
  11: "2xl:col-start-11",
  12: "2xl:col-start-12",
  13: "2xl:col-start-13",
};

export const threeExtraLargeOffsetClassMap: OffsetMap = {
  1: "3xl:col-start-1",
  2: "3xl:col-start-2",
  3: "3xl:col-start-3",
  4: "3xl:col-start-4",
  5: "3xl:col-start-5",
  6: "3xl:col-start-6",
  7: "3xl:col-start-7",
  8: "3xl:col-start-8",
  9: "3xl:col-start-9",
  10: "3xl:col-start-10",
  11: "3xl:col-start-11",
  12: "3xl:col-start-12",
  13: "3xl:col-start-13",
};

export const collectiveOffsetClassMap: BreakpointMap = {
  default: defaultOffsetClassMap,
  sm: smallOffsetClassMap,
  md: mediumOffsetClassMap,
  lg: largeOffsetClassMap,
  xl: extraLargeOffsetClassMap,
  "2xl": twoExtraLargeOffsetClassMap,
  "3xl": threeExtraLargeOffsetClassMap,
};
