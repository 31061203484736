export const tailwindBreakpointsMap = {
  default: 0,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
  "3xl": 2200,
} as const;

export type Breakpoint = keyof typeof tailwindBreakpointsMap;

export type BreakpointMap = {
  [key in Breakpoint]?: object;
};

export type BreakpointProp = {
  [key in Breakpoint]?: string | number;
};
