import { Breakpoint, BreakpointMap } from "./Breakpoint.map";

export type Align = "default" | "center" | "start" | "end" | "stretch";
export type Justify = "default" | "center" | "start" | "end" | "between" | "evenly" | "around";
export type Direction = "column" | "row";

export type BreakpointAlign = {
  [key in Breakpoint]?: Align;
};

export type BreakpointJustify = {
  [key in Breakpoint]?: Justify;
};

export type BreakpointDirection = {
  [key in Breakpoint]?: Direction;
};

export type AlignMap = {
  [key in Align]: string;
};

export type JustifyMap = {
  [key in Justify]: string;
};

export type DirectionMap = {
  [key in Direction]: string;
};

export const defaultAlignClassMap: AlignMap = {
  default: "items-default",
  center: "items-center",
  start: "items-start",
  end: "items-end",
  stretch: "items-stretch",
} as const;

export const smallAlignClassMap: AlignMap = {
  default: "sm:items-default",
  center: "sm:items-center",
  start: "sm:items-start",
  end: "sm:items-end",
  stretch: "sm:items-stretch",
} as const;

export const mediumAlignClassMap: AlignMap = {
  default: "md:items-default",
  center: "md:items-center",
  start: "md:items-start",
  end: "md:items-end",
  stretch: "md:items-stretch",
} as const;

export const largeAlignClassMap: AlignMap = {
  default: "lg:items-default",
  center: "lg:items-center",
  start: "lg:items-start",
  end: "lg:items-end",
  stretch: "lg:items-stretch",
} as const;

export const extraLargeAlignClassMap: AlignMap = {
  default: "xl:items-default",
  center: "xl:items-center",
  start: "xl:items-start",
  end: "xl:items-end",
  stretch: "xl:items-stretch",
} as const;

export const twoExtraLargeAlignClassMap: AlignMap = {
  default: "2xl:items-default",
  center: "2xl:items-center",
  start: "2xl:items-start",
  end: "2xl:items-end",
  stretch: "2xl:items-stretch",
} as const;

export const threeExtraLargeAlignClassMap: AlignMap = {
  default: "3xl:items-default",
  center: "3xl:items-center",
  start: "3xl:items-start",
  end: "3xl:items-end",
  stretch: "3xl:items-stretch",
} as const;

export const defaultJustifyClassMap: JustifyMap = {
  default: "justify-default",
  center: "justify-center",
  start: "justify-start",
  end: "justify-end",
  between: "justify-between",
  evenly: "justify-evenly",
  around: "justify-around",
} as const;

export const smallJustifyClassMap: JustifyMap = {
  default: "sm:justify-default",
  center: "sm:justify-center",
  start: "sm:justify-start",
  end: "sm:justify-end",
  between: "sm:justify-between",
  evenly: "sm:justify-evenly",
  around: "sm:justify-around",
} as const;

export const mediumJustifyClassMap: JustifyMap = {
  default: "md:justify-default",
  center: "md:justify-center",
  start: "md:justify-start",
  end: "md:justify-end",
  between: "md:justify-between",
  evenly: "md:justify-evenly",
  around: "md:justify-around",
} as const;

export const largeJustifyClassMap: JustifyMap = {
  default: "lg:justify-default",
  center: "lg:justify-center",
  start: "lg:justify-start",
  end: "lg:justify-end",
  between: "lg:justify-between",
  evenly: "lg:justify-evenly",
  around: "lg:justify-around",
} as const;

export const extraLargeJustifyClassMap: JustifyMap = {
  default: "xl:justify-default",
  center: "xl:justify-center",
  start: "xl:justify-start",
  end: "xl:justify-end",
  between: "xl:justify-between",
  evenly: "xl:justify-evenly",
  around: "xl:justify-around",
} as const;

export const twoExtraLargeJustifyClassMap: JustifyMap = {
  default: "2xl:justify-default",
  center: "2xl:justify-center",
  start: "2xl:justify-start",
  end: "2xl:justify-end",
  between: "2xl:justify-between",
  evenly: "2xl:justify-evenly",
  around: "2xl:justify-around",
} as const;

export const threeExtraLargeJustifyClassMap: JustifyMap = {
  default: "3xl:justify-default",
  center: "3xl:justify-center",
  start: "3xl:justify-start",
  end: "3xl:justify-end",
  between: "3xl:justify-between",
  evenly: "3xl:justify-evenly",
  around: "3xl:justify-around",
} as const;

export const defaultDirectionClassMap: DirectionMap = {
  column: "flex-col",
  row: "flex-row",
} as const;

export const smallDirectionClassMap: DirectionMap = {
  column: "sm:flex-col",
  row: "sm:flex-row",
} as const;

export const mediumDirectionClassMap: DirectionMap = {
  column: "md:flex-col",
  row: "md:flex-row",
} as const;

export const largeDirectionClassMap: DirectionMap = {
  column: "lg:flex-col",
  row: "lg:flex-row",
} as const;

export const extraLargeDirectionClassMap: DirectionMap = {
  column: "xl:flex-col",
  row: "xl:flex-row",
} as const;

export const twoExtraLargeDirectionClassMap: DirectionMap = {
  column: "2xl:flex-col",
  row: "2xl:flex-row",
} as const;

export const threeExtraLargeDirectionClassMap: DirectionMap = {
  column: "3xl:flex-col",
  row: "3xl:flex-row",
} as const;

export const collectiveAlignClassMap: BreakpointMap = {
  default: defaultAlignClassMap,
  sm: smallAlignClassMap,
  md: mediumAlignClassMap,
  lg: largeAlignClassMap,
  xl: extraLargeAlignClassMap,
  "2xl": twoExtraLargeAlignClassMap,
  "3xl": threeExtraLargeAlignClassMap,
};

export const collectiveJustifyClassMap: BreakpointMap = {
  default: defaultJustifyClassMap,
  sm: smallJustifyClassMap,
  md: mediumJustifyClassMap,
  lg: largeJustifyClassMap,
  xl: extraLargeJustifyClassMap,
  "2xl": twoExtraLargeJustifyClassMap,
  "3xl": threeExtraLargeJustifyClassMap,
};

export const collectiveDirectionClassMap: BreakpointMap = {
  default: defaultDirectionClassMap,
  sm: smallDirectionClassMap,
  md: mediumDirectionClassMap,
  lg: largeDirectionClassMap,
  xl: extraLargeDirectionClassMap,
  "2xl": twoExtraLargeDirectionClassMap,
  "3xl": threeExtraLargeDirectionClassMap,
};
