import { FC, ReactNode, memo } from "react";

import Spacer from "apps/website/components/layout/Spacer/Spacer";
import { Size } from "apps/website/components/layout/Spacer/Spacer.map";
import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";
import { WithTestID } from "apps/website/types";

export interface ISectionSize {
  top: Size;
  bottom: Size;
}

export interface ISection extends WithTestID {
  tag?: "section" | "div";
  size?: Size | ISectionSize;
  theme?: Theme;
  className?: string;
  component?: string;
  children: ReactNode;
  overflow?: "default" | "show",
  id?: string;
}

const Section: FC<ISection> = ({
  tag = "section",
  size = "xl",
  theme = "default",
  component = "Section",
  className = "",
  "data-testid": testId,
  overflow = "default",
  id,
  children,
}) => {
  const Tag = tag;
  const sectionSize = typeof size === "string" ? { top: size, bottom: size } : { top: size.top, bottom: size.bottom };
  const overflowClass = overflow === "default" ? "overflow-hidden" : "";

  return (
    <Tag
      id={id}
      data-testid={testId}
      data-component={component}
      className={`flex flex-col ${className} ${themeRootClassMap[theme]} ${overflowClass}`}
      data-theme={theme}
    >
      <Spacer size={sectionSize.top} />
      <div data-children className="h-full">

        { children }
      </div>
      <Spacer size={sectionSize.bottom} />
    </Tag>
  );
};

export default memo(Section);
