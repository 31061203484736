import { FC, ReactElement, memo } from "react";

import { Theme } from "apps/website/maps/Theme.map";
import Column from "apps/website/components/layout/Column/Column";
import { gridGapsMap } from "apps/website/components/layout/Grid/Grid.map";
import * as GridMap from "apps/website/components/layout/Grid/Grid.map";
import { WithTestID } from "apps/website/types";

export interface IGrid extends WithTestID {
  id?: string;
  tag?: GridMap.AvailableTag;
  gap?: GridMap.Gap,
  className?: string;
  theme?: Theme;
  component?: string;
  children?: ReactElement<typeof Column> | ReactElement<typeof Column>[],
}

const Grid: FC<IGrid> = ({
  id,
  "data-testid": testId,
  tag = "div",
  gap = "default",
  className = "",
  component = "Grid",
  children,
}) => {
  const Tag = tag;
  return (
    <Tag
      data-testid={testId}
      data-component={component}
      className={`grid grid-cols-12 ${gridGapsMap[gap]} ${className}`}
      id={id}
    >
      { children }
    </Tag>
  );
};

export default memo(Grid);
