export type Theme = "default" | "dark" | "dark-alt" | "light" | "color" | "brand" | "brand-alt" | "header" | "form-input" | "footer" | "light-grey" | "blue" | "pink" | "orange" | "green" | "product-card" | "inherit";
export type ThemeWithRepeatable = Theme | "repeatable";

export interface IThemeConfig {
  prefix: string;
  selector: string;
}

type Themes = {
  [key in Theme]: IThemeConfig;
};

export type ThemeMap = {
  [key in Theme]: string;
};

export const themeRootClassMap: ThemeMap = {
  default: "bg-transparent",
  dark: "bg-black",
  "dark-alt": "bg-black",
  light: "bg-white",
  brand: "bg-brand",
  "brand-alt": "bg-brand",
  color: "bg-pink-700", // This no longer works as we override default colours in the tailwind config
  header: "bg-white",
  footer: "bg-black",
  blue: "bg-blue",
  pink: "bg-pink",
  orange: "bg-orange",
  green: "bg-green",
  "light-grey": "bg-light-grey",
  "form-input": "bg-transparent",
  "product-card": "bg-white hover:bg-black transition transition-color ease-in-out duration-300",
  inherit: "bg-inherit",
};

export const pseudoThemeRootClassMap: ThemeMap = {
  default: "before:bg-transparent",
  dark: "before:bg-black",
  "dark-alt": "before:bg-black",
  light: "before:bg-white",
  brand: "before:bg-brand",
  "brand-alt": "before:bg-brand",
  color: "before:bg-pink-700",
  header: "before:bg-white",
  footer: "before:bg-black",
  blue: "before:bg-blue",
  pink: "before:bg-pink",
  orange: "before:bg-orange",
  green: "before:bg-green",
  "light-grey": "before:bg-light-grey",
  "form-input": "before:bg-transparent",
  "product-card": "before:bg-white hover:before:bg-black",
  inherit: "before:bg-inherit",
};

/*
  Currently themes has to be repeated in tailwind.config.js until I
  have time to work out how to import into the tailwind config correctly
*/
export const themes: Themes = {
  default: {
    prefix: "theme-default",
    selector: "[data-theme=\"default\"] &",
  },
  dark: {
    prefix: "theme-dark",
    selector: "[data-theme=\"dark\"] &",
  },
  "dark-alt": {
    prefix: "theme-dark-alt",
    selector: "[data-theme=\"dark-alt\"] &",
  },
  light: {
    prefix: "theme-light",
    selector: "[data-theme=\"light\"] &",
  },
  color: {
    prefix: "theme-color",
    selector: "[data-theme=\"color\"] &",
  },
  brand: {
    prefix: "theme-brand",
    selector: "[data-theme=\"brand\"] &",
  },
  "brand-alt": {
    prefix: "theme-brand-alt",
    selector: "[data-theme=\"brand-alt\"] &",
  },
  header: {
    prefix: "theme-header",
    selector: "[data-theme=\"header\"] &",
  },
  footer: {
    prefix: "theme-footer",
    selector: "[data-theme=\"footer\"] &",
  },
  blue: {
    prefix: "theme-blue",
    selector: "[data-theme=\"blue\"] &",
  },
  pink: {
    prefix: "theme-pink",
    selector: "[data-theme=\"pink\"] &",
  },
  orange: {
    prefix: "theme-orange",
    selector: "[data-theme=\"orange\"] &",
  },
  green: {
    prefix: "theme-green",
    selector: "[data-theme=\"green\"] &",
  },
  "light-grey": {
    prefix: "theme-light-grey",
    selector: "[data-theme=\"light-grey\"] &",
  },
  "form-input": {
    prefix: "theme-form-input",
    selector: "[data-theme=\"form-input\"] &",
  },
  "product-card": {
    prefix: "theme-product-card",
    selector: "[data-theme=\"product-card\"] &",
  },
  inherit: {
    prefix: "theme-inherit",
    selector: "[data-theme=\"inherit\"] &",
  },
};
