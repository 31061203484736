import * as FlexMap from "apps/website/maps/Flex.map";

import { BreakpointClassMap } from ".";

export const breakpointFlexClasses = (
  align: FlexMap.Align | FlexMap.BreakpointAlign,
  justify: FlexMap.Justify | FlexMap.BreakpointJustify,
  direction: FlexMap.Direction | FlexMap.BreakpointDirection,
): BreakpointClassMap[] => [
  [ align, FlexMap.defaultAlignClassMap, FlexMap.collectiveAlignClassMap ],
  [ justify, FlexMap.defaultJustifyClassMap, FlexMap.collectiveJustifyClassMap ],
  [ direction, FlexMap.defaultDirectionClassMap, FlexMap.collectiveDirectionClassMap ],
];
